import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { JOIN_WAITLIST_URL } from '../constants'
import infoImage from '../images/home/dinner.jpeg'
import Layout from '../components/templates/Layout'
import SEO from '../components/simple/SEO'
import { PureInput } from '../components/simple/Input'
import Footer from '../components/simple/Footer'
import Button from '../components/simple/Button'

const Form = styled.form`
  width: 100%;
  height: 100%;
  padding: 75px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  max-width: 1200px;

  > div {
    max-width: 400px;
  }

  @media only screen and (max-width: 950px) {
    padding-top: 120px;
    flex-direction: column;
    max-width: 350px;
  }
`

const InputContainer = styled.div`
  display: flex;
  padding-top: 20px;

  button {
    margin-left: 10px;
    min-width: 150px;
    height: 40px;
  }

  @media only screen and (max-width: 950px) {
    flex-direction: column;

    input {
      margin: 0 0 10px 0;
      width: 100%;
    }

    button {
      margin: 0;
      width: 100%;
    }
  }
`

const InfoImage = styled.img`
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  object-fit: cover;

  @media only screen and (max-width: 950px) {
    margin-top: 60px;
  }

  @media only screen and (min-width: 950px) {
    max-width: 450px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 500px;
  }
`

const Success = styled.p`
  padding-top: 5px;
  color: ${({ theme }) => theme.Success};
`

const Error = styled.p`
  padding-top: 5px;
  color: ${({ theme }) => theme.Accent};
`

const IndexPage = () => {
  const [responseSaved, setResponseSaved] = useState(false)
  const [responseError, setResponseError] = useState('')
  const { register, handleSubmit } = useForm()

  const onSubmit = async values => {
    try {
      const { success, error } = await fetch(JOIN_WAITLIST_URL, {
        method: 'POST',
        body: JSON.stringify({ phoneNumber: values.phoneNumber }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => response.json())

      if (success) setResponseSaved(true)
      setResponseError(error)
    } catch (err) {
      setResponseError('Internal Server Error')
    }
  }

  return (
    <Layout isHome>
      <SEO title="Solo" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Info>
          <div>
            <h2>See You IRL</h2>
            <p>
              A NYC pop up supper club with no permanent address. Launching soon
              in a city near you.
              <br />
              <br />
              Hop on the waitlist here.
            </p>
            <InputContainer>
              <PureInput
                name="phoneNumber"
                ref={register({ required: true })}
                type="tel"
                placeholder="+1 888 888 8888"
              />
              <Button size="small" disabled={responseSaved}>
                Sign Me Up
              </Button>
            </InputContainer>
            {responseSaved && (
              <Success>Thank you. We'll send you a text! :)</Success>
            )}
            {responseError && <Error>{responseError}</Error>}
          </div>
          <InfoImage src={infoImage} />
        </Info>
        <Footer />
      </Form>
    </Layout>
  )
}

export default IndexPage
